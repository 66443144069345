
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.toastr = require('toastr');
window.swal = require('sweetalert2');
//window.GMaps = require('gmaps-master');

require('bootstrap-filestyle');
require('bootstrap-select');
require('jquery-mask-plugin');
require('jquery-lazy');
require('./starrr');
require('owl.carousel');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');

//window.mixitup = require('mixitup/mixitup.min.js');
//mixitup.use(require('mixitup/mixitup-pagination.min.js'));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(function (){

    // Offcanvas
    $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
    });

    // Background image
    $('.background-image[data-src]').each(function(){
        $(this).css('background-image', 'url('+$(this).data('src')+')');
    });

    // Tooltip
    $('[data-tooltip="tooltip"]').tooltip();

    // Lazy
    $('[data-tooltip="tooltip"]').tooltip();

    // Popover
    $('img.lazy').lazy({
        effect: 'fadeIn',
        effectTime: '400',
    });

    // Starrr
    $(".starrr").starrr();

    // File Input
    $(".file-style").filestyle({
        buttonBefore: true,
        text : 'Selecionar arquivo',
        htmlIcon : '<i class="fas fa-upload mr-2"></i>',
        btnClass : 'btn-secondary mb-0',
    });

    // Select
    $('.selectpicker').selectpicker({
        deselectAllText: 'Desmarcar Todos',
        selectAllText: 'Selecionar Todos',
    });

    // mask
    $('.date').mask('00/00/0000');
    $('.zip').mask('00000-000');
    $('.rg').mask('0000000000');

    var VatMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 14 ? '00.000.000/0000-00' : '000.000.000-00999';
    },
    vatOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(VatMaskBehavior.apply({}, arguments), options);
        }
    };
    $('.vat-number').mask(VatMaskBehavior, vatOptions);

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('.celphone').mask(SPMaskBehavior, spOptions);
    $('.phone').mask('(00) 0000-0000');


    /* banners home slider */
    $('#home-slider').owlCarousel({
        items:1,
        lazyLoad:true,
        loop:true,
        margin:0,
        nav:true,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true,
        smartSpeed:600,
    });

    var token = $('meta[name="csrf-token"]').attr('content');
    var back_to_top = $('#back-to-top');
    var images_carousel = $('.images .owl-carousel');
    var grouped_carousel = $('.grouped .owl-carousel');
    var products_slider = $('.products-slider .owl-carousel');
    var promotions_slider = $('.promotions-slider .owl-carousel');
    var brand_slider = $('#brand-slider .owl-carousel');
    var stars = $('#stars');
    var newsletter = $('#form-newsletter');
    var formsearch = $('[name=search_string]');
    var autocomplete = $('.autocomplete');
    var search_open = $('.search-open');
    var xnews = true;

    if ( back_to_top.length )
    {
        var scrollTrigger = 400,
        backToTop = function () {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > scrollTrigger)
            {
                back_to_top.addClass('show');
            } else
            {
                back_to_top.removeClass('show');
            }
        };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        back_to_top.on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }

    brand_slider.owlCarousel({
        autoplay: true,
        loop: true,
        lazyLoad:true,
        margin:20,
        nav:true,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:2,
            },
            1200:{
                items:4,
            },
            1600:{
                items:5,
            },
            1900:{
                items:6,
            },
        }
    });

    products_slider.owlCarousel({
        lazyLoad:true,
        margin:10,
        nav:true,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:2,
            },
            1200:{
                items:4,
            },
        }
    });

    promotions_slider.owlCarousel({
        lazyLoad:true,
        loop:true,
        margin:20,
        nav:true,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1,
            },
            1200:{
                items:2,
            },
        }
    });

    images_carousel.owlCarousel({
    	margin:10,
        nav:false,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1,
            },
            768:{
                items:2,
            },
            1200:{
                items:3,
            },
        }
    });

    grouped_carousel.owlCarousel({
    	margin:10,
        nav:false,
        navText : ['<i class="far fa-angle-left"></i>','<i class="far fa-angle-right"></i>'],
        responsive:{
            0:{
                items:4,
            },
            768:{
                items:6,
            },
            1200:{
                items:8,
            },
        }
    });

    stars.on('starrr:change', function(e, value)
    {
        var route = stars.find(' .starrr').data('route');
        var product_id = stars.find(' .starrr').data('product-id');
        $.ajax({
            type: "POST",
            url: route,
            data: { _token: token, product_id: product_id, count: value },
            success: function(response)
            {
                console.log(response)
            },
            error: function(response)
            {
                console.log(response.status + " " + response.statusText);
            }
        });
    });

    formsearch.keyup(throttle(function(event)
    {
        var string = $(this).val();
        autocomplete.html('');
        var inp = String.fromCharCode(event.keyCode);
        if ( string.length > 2 )
        {
            var sugest;
            $.ajaxSetup(
            {
                header: token
            });

            $.ajax({
                type: "POST",
                url: $(this).attr('data-ajx'),
                data: { _token: token, _string: string },
                success: function(response)
                {
                    autocomplete.html('');
                    var html = '';
                    $.each(response, function (i, suggestion) {
                        html += '<div class="item">' + suggestion.title + '</div>';
                    });
                    autocomplete.html(html).slideDown();
                    $('.autocomplete .item').click(function()
                    {
                        formsearch.val( $(this).html() );
                        autocomplete.slideUp();
                    });
                },
                error: function(response)
                {
                    console.log(response);
                }
            });
        } else
        {
            autocomplete.slideUp();
        }
    })).focusout(function()
    {
        autocomplete.slideUp();
    });

    newsletter.on('submit', function (e)
    {
        e.preventDefault(e);

        if (xnews) {
            xnews = false;
            $.ajaxSetup(
            {
                header: token
            });

            $.ajax({
                type: "POST",
                url: newsletter.attr('action'),
                data: newsletter.serialize(),
                success: function(response)
                {
                    if ( response.error ) {
                        toastr.error(response.error[0]);
                    } else if(response.message) {
                        toastr.success(response.message);
                        newsletter.trigger("reset");
                    }
                    setTimeout(function() {
                        xnews = true;
                    }, 3000);
                },
                error: function(response)
                {
                    console.log(response);
                }
            });
        }
    });

    // E-commerce
    var product_totals = $('form#cart-item-add [name=quantity]');
    var carItemAdd = $('#cart-item-add, .cart-item-add');
    var checkoutQuantityChange = $('#cart .quantity-form .btn-count');
    var shipping = $('#shipping');
    var terms = $('[name=terms]');

    var xx = true;
    var yy = true;

    carItemAdd.on('submit', function (e)
    {
        carItemAdd = $(this);

        $.ajaxSetup(
        {
            header: token
        });

        e.preventDefault(e);
        $.ajax({
            type: "POST",
            url: carItemAdd.attr('action'),
            data: carItemAdd.serialize(),
            success: function(response)
            {
                swal({
                    type: response.type,
                    title: response.title,
                    html: response.html,
                    footer: response.footer,
                    showConfirmButton: false,
                })
            },
            error: function(response)
            {
                swal({
                    title: response.status,
                    text: response.statusTex,
                });
            }
        });
    });

    checkoutQuantityChange.click(function()
    {
        if ( xx )
        {
            xx = false;
            var click = $(this);
            var product = click.closest('.product');
            var form = click.closest('form');
            var quantity = form.find('[name=quantity]');
            var price = form.find('[name=price]');
            var total = form.find('[name=total]');
            var count = parseInt( quantity.val() );

            product.find('.total').addClass('spin');
            $('.totals').addClass('spin');

            if ( !isNaN(count) )
            {
                if ( click.data('count') == '+1' && quantity.attr('max') > count ) {
                    quantity.val(count + 1);
                }
                if ( click.data('count') == '-1' && count > 1) {
                    quantity.val(count - 1);
                }

                $.ajaxSetup(
                {
                    header: token
                });

                $.ajax({
                    type: "POST",
                    url: form.attr('action'),
                    data: {'_token' : token, 'quantity' : quantity.val()},
                    success: function(response)
                    {
                        var _total = price.val() * quantity.val();
                        total.val(_total);

                        var totals = $('[name=total]');
                        var _totals = 0;
                        totals.each(function() {
                            _totals += Number($(this).val());
                        });
                        product.find('.total strong').html( 'R$ ' + _total.toFixed(2).replace('.',',') );
                        $('.totals strong span').html( 'R$ ' + _totals.toFixed(2).replace('.',',') );
                        product.find('.total').removeClass('spin');
                        $('.totals').removeClass('spin');

                        if (response.type == 'success') {
                            toastr.success(response.toastr);
                        } else {
                            toastr.error(response.toastr);
                        }

                        xx = true;
                    },
                    error: function(response)
                    {
                        console.log(response.status + " " + response.statusText);
                    }
                });
            }
        }
    });

    shipping.on('submit', function (e)
    {
        e.preventDefault(e);

        if ( yy )
        {
            yy = false;
            $('.shipping-value').addClass('spin');
            shipping.find('[name=product_qtd]').val( $('form#cart-item-add [name=quantity]').val() );

            $.ajax({
                type: "POST",
                url: shipping.attr('action'),
                data: shipping.serialize(),
                success: function(response)
                {
                    var _html = response;
                    yy = true;
                    $('.shipping-value div.info').html(_html);
                    $('.shipping-value').removeClass('spin');
                    $('.totals').removeClass('spin');
                },
                error: function(response)
                {
                    console.log(response.status + " " + response.statusText);
                }
            });
        }
    });

    product_totals.on("change paste keyup", function()
    {
        var price = $('form#cart-item-add [name=price]').val();
        var quantity = $('form#cart-item-add [name=quantity]').val();
        var total = price * quantity;
        $('.subtotal strong').html( 'R$ ' + total.toFixed(2).replace('.',',') );
    });

    terms.change(function()
    {
        if ($(this).is(':checked')) {
            $('#checkbox-finish').prop('disabled', false);
        } else {
            $('#checkbox-finish').prop('disabled', true);
        }
    });
    // / E-commerce
});

function throttle(f, delay){
    var timer = null;
    return function(){
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = window.setTimeout(function(){
            f.apply(context, args);
        },
        delay || 500);
    };
}
